import { BookingList } from '../BookingList/BookingList';
import { useSelector } from 'react-redux';
import { Box, Fade, Stack, Typography } from '@mui/material';
import { useLocation } from "react-router-dom";
import { AppSettings } from "../../customization";
import { getContrastColor } from "../../lib/helpers";

export function BookingData() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const consult = params.get('consult') ?? false;
  const orderList = useSelector((store) => store.cartSlice.selectedStaff);

  const { style } = AppSettings

  return (
      <Fade in style={{ transitionDuration: '1s' }}>
        {orderList.length ? (
            <Stack gap={'1rem'} maxWidth={'40rem'}>
              <Typography
                  fontWeight={'bold'}
                  variant={'h3'}
                  color={'primary'}
                  gutterBottom
                  sx={{
                    fontVariationSettings: `'wdth' 100, 'wght' 900`,
                    textAlign: 'center',
                  }}
              >
                Congratulations!
              </Typography>
              <Box>
                <Typography variant={'h5'} sx={{
                  fontVariationSettings: `'wdth' 95, 'wght' 700`,
                  color: getContrastColor(style.main.backgroundColor)
                }}>
                  Your remote {orderList.length > 1 ? 'team' : 'employee'} almost ready.
                </Typography>
                <Typography variant={'body1'}
                            sx={{
                              color: getContrastColor(style.main.backgroundColor)
                            }}>
                  Please check everything again, fill out the form and we will arrange a meeting with the selected
                  candidates
                </Typography>
              </Box>

              <Typography color={'primary'} padding={'0'} sx={{
                fontVariationSettings: `'wdth' 95, 'wght' 700`,
              }}>
                {`${orderList.length} employee${orderList.length > 1 ? 's' : ''} now added for interview:`}
              </Typography>
              <Box>
                <BookingList orderList={orderList}/>
              </Box>
            </Stack>
        ) : (
            <Box sx={{ width: '40vw', alignSelf: 'center', textAlign: 'center' }}>
              {/*<StepsList/>*/}
              <Typography variant='h5' sx={{ color: getContrastColor(style.main.backgroundColor) }}>
                {consult ? 'Didn\'t find what you were looking for?' : 'Lorem ipsum dolor sit amet.'}
              </Typography>
              <Typography sx={{
                lineHeight: 2,
                letterSpacing: '2px',
                mb: '1rem',
                color: getContrastColor(style.main.backgroundColor)}}>
                {consult
                    ? 'Schedule meet up and we will try to find something special for you.'
                    : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur sed pariatur quod illo deleniti exercitationem! Repudiandaiste libero cum quia. Eius quam libero nisi quisquam iure reiciendis ut aliquid ipsa tempore mollitia in, dignissimos vero suscipit doloribus officiis officia? Doloremque laboriosam ab nam mollitia quod unde modi nobis corrupti natus, earum consequuntur, odit iste ducimus quis! Ipsum perspiciatis, ipsam impedit temporibus molestias dolorum dolorem asperiores ut ab quos atque voluptatibus! Harum.'}
              </Typography>
            </Box>
        )}
      </Fade>
  );
}
