import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
} from "@mui/material";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import FilterListIcon from '@mui/icons-material/FilterList';
import CustomBreadcrumbs from "../CustomBreadcrumbs";
import FiltersDrawer from "../FiltersDrawer";
import { AppSettings } from "../../customization";
import { getContrastColor } from "../../lib/helpers";

function Layout() {

  const location = useLocation();
  const initValue = (location.pathname !== '/' ? 2 : 0)
  const [value, setValue] = useState(initValue);
  const cartCounter = useSelector(
      (store) => store.cartSlice.selectedStaff.length
  );
  const current = useSelector((store) => store.filterSlice.filteredEmployees);
  const { isFiltered, totalEmployees } = useSelector((store) => store.filterSlice);
  const [filterOpen, setFilterOpen] = useState(false);

  useEffect(() => {
    location.pathname === '/' && setValue(0)
  }, [location.pathname]);

  const { style } = AppSettings

  return (
      <div style={{
        width: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: "column",
        alignItems: 'center',
      }}>
        <header>
          {location.pathname !== '/' && location.pathname !== '/404' && <CustomBreadcrumbs/>}
        </header>
        <main style={{ width: 'inherit', display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',}}>
          <FiltersDrawer
              filterOpen={filterOpen}
              setFilterOpen={setFilterOpen}
              current={current}
              total={totalEmployees}
          />
          <Outlet/>
        </main>
        <footer>
          <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1201,  borderRadius: 0, }} elevation={3}>
            <BottomNavigation
                sx={{
                  backgroundColor: style.footer.backgroundColor,
                  height: '5rem',
                  borderTop: '2px solid `style.primaryColor`'
                }}
                showLabels
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
            >
              <BottomNavigationAction
                  component={Link}
                  to='/'
                  label="Staff"
                  icon={<PeopleIcon/>}
                  onClick={() => filterOpen && setFilterOpen(!filterOpen)}
                  sx={{
                    maxWidth: 'unset',
                    flexGrow: 1,
                    borderRadius: 0,
                    backgroundColor: location.pathname === '/' && style.footer.BtnBGColor,
                    color: location.pathname === '/' && getContrastColor(style.footer.BtnBGColor),
                  }}
                  selected={location.pathname === '/'}
              />

              {location.pathname === "/" && (
                  <BottomNavigationAction
                      component={Link}
                      sx={{
                        backgroundColor: style.footer.filterBtnBGColor || 'grey',
                        maxWidth: '100px',
                        height: '100%',
                        color: 'white',
                        '&.Mui-selected': {
                          backgroundColor: style.footer.filterBtnBGColor || 'grey',
                          color: getContrastColor(style.footer.BtnBGColor),
                        },
                        '&.filter-selected': {
                          backgroundColor: 'green',
                        },
                      }}
                      label="Filter"
                      icon={<FilterListIcon/>}
                      onClick={() => setFilterOpen(!filterOpen)}
                      className={isFiltered ? 'filter-selected' : ''}
                  />
              )}
              <BottomNavigationAction
                  component={Link}
                  to='/cart'
                  label="Cart"
                  icon={
                    <Badge badgeContent={cartCounter} color="secondary" >
                      <ShoppingCartIcon/>
                    </Badge>
                  }
                  onClick={() => filterOpen && setFilterOpen(!filterOpen)}
                  sx={{
                    maxWidth: 'unset',
                    flexGrow: 1,
                    borderRadius: 0,
                    backgroundColor: location.pathname === '/cart' && style.main.primaryColor
                  }}
                  selected={location.pathname === '/cart'}
              />
            </BottomNavigation>
          </Paper>
        </footer>
      </div>
  );
}

export { Layout };
