import { Box, FormGroup, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { Filter } from "./components/Filter";
import { Controls } from "./components/Controls";
import { AppSettings } from "../../customization";
import RateSlider from "./components/RateSlider";
import React from "react";

export function Filters({ setFilterOpen, current, total }) {
  const {
    departments,
    positions,
    skills,
    filteredDepartments,
    filteredPositions,
    filteredSkills,
    filteredLangs,
    filteredIndustries,
    languages,
    industries
  } = useSelector(state => state.filterSlice);
  const { filter, style } = AppSettings

  return (
      <Box role={'Filter'} width={'100%'} display={'flex'} sx={{
        flexFlow: 'column nowrap',
        alignItems: 'center',
        gap: '1rem',
        height: 'calc(100% - 6rem)'
      }}>
        {departments && filter.department.length !== 1 && (
            <Filter placeholder={"Employee category"} values={filteredDepartments} id="deps"/>)}
        {positions && (<Filter placeholder={"Employee positions"} values={filteredPositions} id="pos"/>)}
        {skills && (<Filter placeholder={"Employee skills"} values={filteredSkills} id="skills"/>)}
        {languages && (<Filter placeholder={"Employee languages"} values={filteredLangs} id="langs"/>)}
        {industries && (<Filter id="industries" values={filteredIndustries} placeholder={"Employee domain"}/>)}

        <FormGroup sx={{
          padding: '1rem .5rem',
          display: 'flex',
          flexFlow: 'column nowrap',
          gap: '.75rem',
          border: '1px solid #ccc',
          borderRadius: '.25rem',
          width: '100%',
          minWidth: '15rem',
          marginBottom: 'auto'
        }}>
          <RateSlider id="rate"/>
        </FormGroup>

        <Box display={'flex'} width={'100%'} gap={'.3rem'}>
          <Typography
              sx={{
                color: style.primaryColor,
                fontVariationSettings: `'wdth' 95, 'wght' 700`,
                fontSize: '1.1rem',
              }}
          >
            Employees shown:
          </Typography>
          <Typography
              sx={{
                color: style.primaryColor,
                fontVariationSettings: `'wdth' 95, 'wght' 700`,
                fontSize: '1.1rem',
              }}
          >
            {current}
          </Typography>
          <Typography
              sx={{
                color: style.primaryColor,
                fontVariationSettings: `'wdth' 95, 'wght' 700`,
                fontSize: '1.1rem',
              }}
          >
            / {total}
          </Typography>
        </Box>
        <Controls setFilterOpen={setFilterOpen}/>
      </Box>
  );
}
