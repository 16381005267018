import logoFull from '../src/assets/logo.svg'

export const AppSettings = {
  style: {
    main: {
      // HEX color
      backgroundColor: '#ffffff',
      // HEX color
      primaryColor: '#9A58B5',
      // HEX color
      secondaryColor: '#88d369',
      // string
      boxShadow: '0 0.2rem 1.5rem -0.3rem rgba(0,0,0,.15)',
      // number
      borderRadius: '1rem',
      // string
      logo: logoFull
    },
    card: {
      // css property = background
      avatarBackground: '#D5E3F3',
      // HEX color or RGB
      chipBorderColor: '#9A58B5',
      // HEX color or RGB
      chipTextColor: '#9A58B5',
    },
    cv: {
      // css property = background
      avatarBackground: '#D5E3F3',
    },
    cart: {
      // css property = background
      formBackground: '#D5E3F3',
      // HEX color or RGB
      bookingListBorderColor: '',
    },
    footer: {
      // HEX color or RGB
      backgroundColor: '',
      // HEX color or RGB
      BtnBGColor: '#9A58B5',
      // HEX color or RGB
      filterBtnBGColor: '#5a5a5a',
    },
  },
  cardsConfig: {
    // number
    priceCoeff: 1
  },
    // string
  affiliateCode: '',

  // Departments IDs:
  //    DESIGNERS: 13
  //    DEVELOPERS: 36
  //    MANAGERS: 23
  //    MARKETERS: 14
  //    VIDEOGRAPHERS: 37

  filter: {
    department: [23]
  }
}

// export const AppSettings = {
//     style: {
//         // HEX color
//         backgroundColor: '#ffdc38',
//         // HEX color
//         footerBGColor: '#ffffff',
//         // HEX color
//         footerBtnBGColor: '#000000',
//         // HEX color
//         primaryColor: '#2196f3',
//         secondaryColor: '#FB8C00',
//         // string
//         boxShadow: '0 0.2rem 1.5rem -0.3rem rgba(0,0,0,.2)',
//         avatarBackground: 'linear-gradient(180deg,#ffdd3c,#e4a111)',
//         cvAvatarBackground: 'linear-gradient(180deg,#ffdd3c,#e4a111)',
//         // number
//         borderRadius: 0
//     },
//
//     cardsConfig: {
//         priceCoeff: 3
//     },
//
//     // Departments IDs:
//     //    DESIGNERS: 13
//     //    DEVELOPERS: 36
//     //    MARKETERS: 14
//     //    SALES MANAGERS: 21
//     //    VIRTUAL ASSISTANTS: 23
//
//     filter: {
//         department: [36]
//     }
// }
